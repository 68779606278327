<script setup lang="ts">
import type { KeenSliderOptions } from "keen-slider";

import type { Promotion } from "@/types";

const { t } = useT();
const loginGuard = useLoginGuard();
const router = useRouter();
const { open } = useAppModals();
const { data: appInit } = useAppInitData();
const { homePromotions, openCash } = usePromotionsData();
const { referralInfo, handleClickCopyLink, execute: executeReferralsInfo } = useReferralsInfo();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const sliderRef = ref();

const currencySymbol = computed(() => appInit.value?.currencySymbol ?? "$");
const wheelText = computed(() =>
	appInit.value?.isGuest
		? "Create an account and get your prize now!"
		: "Boost your game with free coins - get your prize now!"
);

const sliderOptions: KeenSliderOptions = {
	slides: { perView: "auto" },
	created(slider) {
		setTimeout(() => {
			slider.update();
		}, 1500);
	}
};

const handleNavigateToPromotions = () => {
	loginGuard({
		success: () => {
			router.push("/promotions/");
		},
		fail() {
			open("LazyOModalSignup");
		}
	});
};
const getImgFullPath = (src: string) => {
	const isNuxtImg = src.includes("nuxt-img");
	return isNuxtImg ? src : `${baseImageUrl}${src}`;
};

const handleClick = (promotion: Promotion) => {
	loginGuard({
		success: () => {
			if (promotion?.data?.enabled) {
				dispatchGAEvent({ event: "click_button", location: "home", buttonName: "make_deposit" });
				window?.$store?.gaCash?.deposit?.({
					location: "home",
					step: "view_payments_window",
					activePackage: promotion?.data?.money
				});

				openCash(promotion);
			}
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handleWheelClick = () => {
	loginGuard({
		success: () => {
			open("LazyOModalDailyWheel");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handleSeasonClick = () => {
	loginGuard({
		success: () => {
			if (appInit.value?.season?.isEnabled) {
				open("LazyOModalSeasonCards");
			}
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handleInviteClick = () => {
	loginGuard({
		success: async () => {
			if (!referralInfo?.value?.referralUrl) {
				await executeReferralsInfo();
			}

			handleClickCopyLink();
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

watch(homePromotions, async () => {
	await nextTick(() => {
		sliderRef.value?.slider?.update();
	});
});

watch(
	() => !appInit.value?.isGuest,
	() => {
		nextTick(() => {
			sliderRef.value?.slider?.update();
		});
	}
);
</script>
<template>
	<div class="promotions">
		<MHomeSectionHeader
			title="Promotions"
			tidTitle="promotions"
			tidShow="promo-show-all"
			icon="home/promotion"
			is-show-all
			@handle-click="handleNavigateToPromotions"
		/>
		<ASlider ref="sliderRef" :options="sliderOptions">
			<div v-if="appInit?.prizeDrops?.isActive" class="keen-slider__slide" data-tid="promo-card">
				<MPromotionPrizeDrops data-tid="promo-coin-drop" />
			</div>
			<div v-if="appInit?.scratchCardLottery?.isActive" class="keen-slider__slide" data-tid="promo-card">
				<MPromotionScratchCards data-tid="promo-scratch-card" />
			</div>
			<div v-if="appInit?.magicBox?.isActive" class="keen-slider__slide" data-tid="promo-card">
				<MPromotionMagixBoxes />
			</div>
			<template v-if="homePromotions?.promotions?.length && homePromotions.promotions.length > 0">
				<div
					v-for="(card, index) in homePromotions.promotions"
					:key="index"
					class="keen-slider__slide"
					data-tid="promo-card"
				>
					<MPromotionWheelOfTreasure v-if="card.type === 'depositStreak' && appInit?.depositStreak?.isActive" />
					<MPromotionTournament v-else-if="card.type === 'tournament'" />
					<MPromotionIndependenceSeason
						v-else-if="card.type === 'season'"
						:finishedAt="card?.data?.finishedAt"
						@click="handleSeasonClick"
					/>
					<MPromotionWheel
						v-else-if="card.type === 'dailyWheel'"
						:image="getImgFullPath('/nuxt-img/wheel-promo/bg.png')"
						:type="card.type"
						:text="t(wheelText)"
						@click="handleWheelClick"
					/>
					<MPromotionInvite
						v-else-if="card.type === 'invite'"
						image="/nuxt-img/promotion/invite-bg.png"
						:coins="card.package?.coins || 0"
						:entries="card.package?.entries || 0"
						@click="handleInviteClick"
					/>
					<MPromotionItem
						v-else
						:type="card.type"
						:enabled="card.data?.enabled"
						:showAvailable="!!card.data?.promoOfferPreset?.availableTill"
						:image="getImgFullPath(card.package?.imagePopup || '')"
						:coins="card.package?.coins || 0"
						:entries="card.package?.entries || 0"
						:badge="card.badgeLabel"
						:bestDeal="!!card.data?.promoOfferPreset?.bestDeal"
						:mostPopular="!!card.data?.promoOfferPreset?.mostPopular"
						:specialOffer="false"
						:date="card.date"
						:money="card.data?.money"
						:currencySymbol="currencySymbol"
						:subType="card.data?.promoOfferPreset?.subType"
						:title="card?.data?.promoOfferPreset?.title"
						@click="handleClick(card)"
					/>
				</div>
			</template>
		</ASlider>
	</div>
</template>

<style scoped lang="scss">
.promotions {
	padding: 24px 0;
	position: relative;

	@include media-breakpoint-down(md) {
		padding: 0;
		.header {
			padding: 0 16px;
		}
	}

	&:deep(.keen-slider) {
		display: flex;
		position: relative;
		padding-top: 24px;
		@include media-breakpoint-up(md) {
			mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) calc(100% - 60px), rgba(0, 0, 0, 0) 100%);
		}

		@include media-breakpoint-down(md) {
			padding-top: 20px;
		}

		.keen-slider__slide {
			width: calc(292px + 4px);
			padding-right: 4px;
		}
	}
}
</style>
